export const BOOKING_TYPE_MATCH = 1000;
export const BOOKING_TYPE_LESSON = 5000;
export const BOOKING_TYPE_TOURNAMENT = 6000;
export const BOOKING_TYPE_MAINTENANCE = 7000;
export const BOOKING_TYPE_RAIN = 7001;
export const BOOKING_TYPE_CLOSED = 7002;
export const BOOKING_TYPE_CLUB_EVENT = 8000;
export const BOOKING_TYPE_MENTORING = 8001;
export const BOOKING_TYPE_JUNIOR_PROGRAM = 8002;
export const BOOKING_TYPE_LADDER = 8003;

export const BOOKING_ERROR_MESSAGE = "Please correct booking errors";

export const Role = {
  ADMIN: 4000,
  MANAGER: 3000,
  PRO: 2500,
  MEMBER: 2000,
  JUNIOR: 1500,
  RETRICTED_MEMBER: 1000,
  GUEST: 500,
  AUTHENTICATED: 100,
  NOROLE: 0,
};

export const ROLE_TYPES = {
  GUEST_TYPE: 100,
  RESTRICTED_MEMBER_TYPE: 200,
  MEMBER_TYPE: 300,
  INSTRUCTOR_TYPE: 350,
  MANAGER_TYPE: 400,
  SYSADMIN_TYPE: 1000,
};
