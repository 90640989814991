<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center"> Page Not Found </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
